<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">{{ cardTitle }}</h4>
    </md-card-header>
    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('device.slot.slotListenerForm.name')"
                    v-model="form.name"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.slotListenerForm.name") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="listenerTypes"
                    :label="$t('device.slot.slotListenerForm.listenerType')"
                    :rules="[rules.required]"
                    v-model="form.listenerTypeId"
                  ></v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{
                    $t("tooltips.slotListenerForm.listenerType")
                  }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="conditionTypes"
                    :label="$t('device.slot.slotListenerForm.listenerType')"
                    :rules="[rules.required]"
                    v-model="form.condition.conditionTypeId"
                  ></v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{
                    $t("tooltips.slotListenerForm.conditionType")
                  }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div class="row">
            <math-condition
              v-if="form.listenerTypeId === 1"
              :sign="conditionSymbol"
              v-model="form.condition.value"
              :rules="[rules.required]"
            />
          </div>
          <div class="row">
            <notification-channel-listeners
              :selectedUsers="form.selectedUsers"
              :selectedChannels="form.selectedChannels"
              @users="setUsers"
              @channels="setChannels"
            />
          </div>
        </v-form>
        <div class="row">
          <div class="col-12 text-right">
            <tgl-save-button
              ref="saveBtn"
              :entity="entity"
              :dispatch="entity.dispatch"
              :action="editMode ? 'editado' : 'creado'"
              :redirect="entity.redirect"
              :validateForm="handleSubmit"
            ></tgl-save-button>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import form from "../../mixins/form";
import MathCondition from "./MathCondition.vue";
import NotificationChannelListeners from "./NotificationChannelListeners";
import { mapGetters } from "vuex";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";
import TGCTextField from "@/components/UI/TGCTextField";

export default {
  name: "slot-listener-form",
  components: {
    MathCondition,
    NotificationChannelListeners,
    TglSaveButton,
    TGCTextField,
  },
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "ow",
    },
  },
  data: () => ({
    form: {
      id: 0,
      name: "",
      listenerTypeId: 1,
      condition: {
        conditionTypeId: 1,
        value: null,
      },
      isActive: true,
      selectedUsers: [],
      selectedChannels: [],
    },
    editMode: false,
    listenerTypes: [],
    conditionTypes: [],
  }),
  watch: {
    user() {
      this.initCreateItemMode();
    },
  },
  mounted() {
    if (this.$route.params.eventListener) {
      this.editMode = true;
      this.getItem();
    } else {
      this.initCreateItemMode();
      this.form.listenerTypeId = 1;
      this.form.conditionTypeId = 1;
    }
    this.getListenerTypes();
    this.getConditionTypes();
  },
  computed: {
    ...mapGetters(["getTheme"]),
    cardTitle() {
      return this.editMode
        ? this.$t("eventListeners.cardTitleEdit")
        : this.$t("eventListeners.cardTitleCreate");
    },
    cardSubtitle() {
      return this.editMode
        ? this.$t("eventListeners.cardSubtitleEdit")
        : this.$t("eventListeners.cardSubtitleCreate");
    },
    user() {
      return this.$store.getters["user/user"];
    },
    conditionSymbol() {
      const symbol = this.conditionTypes.find(
        (conditionType) =>
          conditionType.id === this.form.condition.conditionTypeId
      );
      if (symbol) return symbol.name;
      return null;
    },
    entity() {
      let params = this.formatItem();
      return {
        name: "listener",
        params,
        dispatch: this.editMode
          ? "listener/updateSlotListener"
          : "listener/storeSlotListener",
        redirect: {
          name: "slotEventListeners",
          params: {
            device: this.$route.params.device,
            slot: this.$route.params.slot,
          },
        },
      };
    },
  },
  methods: {
    initCreateItemMode() {
      if (!this.editMode && this.user) this.form.selectedUsers = [this.user.id];
    },
    getListenerTypes() {
      this.$store
        .dispatch("listener/getSlotListenerTypes")
        .then((listenerTypes) => (this.listenerTypes = listenerTypes));
    },
    getConditionTypes() {
      this.$store
        .dispatch("listener/getConditionTypes")
        .then((conditionTypes) => (this.conditionTypes = conditionTypes));
    },
    handleSubmit() {
      return this.$refs.form.validate();
    },
    getItem() {
      this.$store
        .dispatch("listener/getSlotListener", { ...this.$route.params })
        .then((eventListener) => {
          this.setItem(eventListener);
        });
    },
    formatItem() {
      const {
        name,
        listenerTypeId,
        condition,
        selectedUsers,
        selectedChannels,
        isActive,
      } = this.form;
      const { slot, device } = this.$route.params;
      let listener = {
        name,
        listenerTypeId,
        isActive,
        condition,
        selectedUsers,
        selectedChannels,
        deviceId: device,
        slotId: slot,
      };
      if (this.editMode) listener.id = this.form.id;
      return {
        listener,
        slot,
        device,
      };
    },
    setItem(item) {
      if (!item.condition) {
        this.form.condition = {
          conditionTypeId: 1,
          value: null,
        };
      } else {
        this.form.condition = item.condition;
      }
      this.form.id = item.id;
      this.form.name = item.name;
      this.form.listenerTypeId = item.listenerTypeId;
      this.form.selectedUsers = item.selectedUsers;
      this.form.selectedChannels = item.selectedChannels;
    },
    setUsers(users) {
      this.form.selectedUsers = users;
    },
    setChannels(channels) {
      this.form.selectedChannels = channels;
    },
  },
};
</script>