<template>
  <div class="container-fluid">
    <v-form>
      <div class="row">
        <div class="col-md-12">
          <strong>{{
            $t("device.deviceEventListenerForm.communicationChannels")
          }}</strong>
        </div>
        <div class="col-md-6 col-12">
          <div class="row d-flex align-center m-0 mb-5">
            <div class="col col-10">
              <v-select
                :items="notificationChannels"
                item-text="name"
                item-value="id"
                :label="
                  $t('device.deviceEventListenerForm.communicationChannels')
                "
                @input="emitChannels"
                chips
                multiple
                :value="selectedChannels"
              />
            </div>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  size="20"
                  class="col col-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline</v-icon
                >
              </template>
              <span>{{ $t("tooltips.deviceListenerForm.channels") }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="row d-flex align-center m-0 mb-5">
            <div class="col col-10">
              <v-select
                :value="selectedUsers"
                :items="users"
                item-text="name"
                item-value="id"
                :label="$t('device.deviceEventListenerForm.users')"
                chips
                multiple
                @input="emitUsers"
              />
            </div>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  size="20"
                  class="col col-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline</v-icon
                >
              </template>
              <span>{{ $t("tooltips.deviceListenerForm.users") }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["selectedUsers", "selectedChannels"],
  data: () => ({
    notificationChannels: [],
    users: [],
  }),
  mounted() {
    this.$store
      .dispatch("notificationChannel/getNotificationChannels")
      .then(
        (notificationChannels) =>
          (this.notificationChannels = notificationChannels)
      );
    this.$store
      .dispatch("user/getOrganizationUsers")
      .then((users) => (this.users = users));
  },
  methods: {
    emitChannels(e) {
      this.$emit("channels", e);
    },
    emitUsers(e) {
      this.$emit("users", e);
    },
  },
};
</script>