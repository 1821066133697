<template>
  <div class="math-condition">
    <span>
      <em>{{ $t("device.slot.slotListenerForm.calibratedValue") }}</em>
    </span>
    <span class="sign">{{ sign }}</span>

    <div class="col-lg-2 col-8">
      <div class="row d-flex align-center m-0 mb-5">
        <div class="col col-10">
          <v-text-field
            :label="$t('device.slot.slotListenerForm.value')"
            :value="value"
            :rules="rules"
            @input="emit"
          />
        </div>
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              size="20"
              class="col col-2"
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline</v-icon
            >
          </template>
          <span>{{ $t("tooltips.slotListenerForm.value") }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sign", "value", "rules"],
  name: "math-condition",
  methods: {
    emit(value) {
      this.$emit("input", value);
    },
  },
};
</script>