<template>
  <v-text-field
    :value="value"
    @input="updateValue"
    :rules="rules"
    :label="label"
    :type="type"
    :counter="counter"
  />
</template>

<script>
import inputWithFormatter from "@/mixins/inputWithFormatter";
export default {
  name: "tgc-text-field",
  props: {
    value: {
      required: true,
    },
    type: {
      required: false,
      type: String,
      default: "text",
    },
  },
  mixins: [inputWithFormatter],
};
</script>