<template>
  <div>
    <v-btn
      color="primary"
      class="tlg-save-btn text-capitalize"
      @click="saveItem"
    >
      <span>Guardar</span>
      <v-icon right size="20"> mdi-content-save </v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline lighten-2">
          Guardado correctamente
        </v-card-title>

        <v-card-text>
          Su {{ entity.name }} ha sido {{ action }} correctamente, para
          continuar presione <b>Ir a {{ entity.name }}s.</b>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="goBack" color="primary" type="button">
            <span class="text-capitalize"
              >Ir
              <span class="text-lowercase">
                a {{ entity.name
                }}{{ entity.name === "trabajador" ? "es" : "s" }}</span
              >
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    entity: { required: true, type: Object },
    redirect: { required: true, type: Object },
    action: { required: true, type: String },
    dispatch: { required: true, type: String },
    validateForm: { required: false },
  },
  mounted() {},
  methods: {
    saveItem() {
      if (this.validateForm())
        this.$store
          .dispatch(this.dispatch, this.entity.params)
          .then((data) => {
            console.log(data);
            this.dialog = true;
          })
          .catch((message) => {
            console.log("error desde save btn", message);
          });
    },
    goBack() {
      this.dialog = false;
      this.$router.push(this.redirect);
    },
  },
};
</script>

<style lang="sass">
.tgl-save-btn
  span
    font-size: 1rem
    font-weight: 400
</style>