<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pa-0 ma-0 tgl-page-header">
          <tgl-breadcrumbs :items="breadcrumbs" />
          <eventListenerActions v-if="$route.params.eventListener" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 pa-0 ma-0">
          <slot-listener-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "../../components/UI/TglBreadcrumbs";
import EventListenerActions from "../../components/EventListeners/Actions";
import SlotListenerForm from "../../components/EventListeners/SlotListenerForm";

export default {
  components: {
    TglBreadcrumbs,
    EventListenerActions,
    SlotListenerForm,
  },
  data() {
    return {
      device: null,
      slot: null,
      eventListener: null,
    };
  },
  computed: {
    breadcrumbs() {
      let breadcrumbs = [
        {
          label: "Dispositivos",
          disabled: false,
          to: { name: "devices" },
        },
        {
          label: this.device ? this.device.alias : this.$route.params.device,
          disabled: false,
          to: {
            name: "deviceDetails",
            params: { device: this.$route.params.device },
          },
        },
        {
          label: "Slots",
          disabled: false,
          to: {
            name: "deviceSlots",
            params: { device: this.$route.params.device },
          },
        },
        {
          label: this.slot ? this.slot.alias : this.$route.params.slot,
          disabled: false,
          to: {
            name: "slotProfile",
            params: {
              device: this.$route.params.device,
              slot: this.$route.params.slot,
            },
          },
        },
        {
          label: "Observadores de eventos",
          disabled: false,
          to: {
            name: "slotEventListeners",
            params: {
              device: this.$route.params.device,
              slot: this.$route.params.slot,
            },
          },
        },
      ];
      if (this.$route.name === "slotEventListenerEdit") {
        breadcrumbs.push({
          label: this.eventListener
            ? this.eventListener.name
            : this.$route.params.eventListener,
          disabled: true,
          to: { name: "slotEventListenerEdit", params: this.$route.params },
        });
      }
      breadcrumbs.push({
        label: this.$t(this.$route.meta.title.toLowerCase()),
        disabled: true,
        to: { name: "slotEventListenerCreate" },
      });
      return breadcrumbs;
    },
  },
  methods: {
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
        });
    },
    getSlot() {
      this.$store
        .dispatch("slot/getSlot", {
          device: this.$route.params.device,
          slot: this.$route.params.slot,
        })
        .then((data) => {
          this.slot = data;
        });
    },
    getSlotListener() {
      this.$store
        .dispatch("listener/getSlotListener", { ...this.$route.params })
        .then((data) => {
          this.eventListener = data;
        });
    },
  },
  mounted() {
    this.getDevice();
    this.getSlot();
    if (this.$route.name === "slotEventListenerEdit") {
      this.getSlotListener();
    }
  },
};
</script>