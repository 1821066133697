<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        @click="(e) => e.preventDefault()"
        :href="item.to.name"
        :disabled="item.disabled"
      >
        <router-link :is="item.disabled ? 'span' : 'router-link'" :to="item.to">
          {{ item.label }}
        </router-link>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  props: {
    items: { required: true, type: Array },
  },
};
</script>